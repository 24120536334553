export default {
  BASE_URL: "https://surocode.com.au/",
  PRICING_URL: "questionCost.json",
  FIELD_REQUIRED: "This field is required.",
  INVALID_NAME: "Please enter a valid name",
  INVALID_EMAIL: "Please enter a valid email",
  INVALID_PHONE: "Please enter a valid phone number",
  INVALID_APPLICATION_NAME: "Please enter a valid application name",
  ERROR_REQUEST_MESSAGE:
    "There was a problem trying to perform your request. Please try again.",
};
