import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import "jquery";
import "bootstrap/dist/js/bootstrap";

import { spring, AnimatedSwitch } from "react-router-transition";

// Main routers
import Overview from "./overview/overview";
import Mobileapp from "./mobileapp/mobileapp";
import Webapp from "./webapp/webapp";
import Uiux from "./uiux/uiux";
import Contact from "./contact/contact";
import About from "./about/about";
import CostCalculator from "./costCalculator/costCalculator";

// Footer
import FooterContent from "./footer/FooterContent";

import "./index.css";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  NavLink,
} from "react-router-dom";

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

class Navigator extends React.Component {
  constructor(props) {
    super(props);
    this.state = { style: "normal" };
    this.updateStyles = this.updateStyles.bind(this);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.updateStyles);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateStyles);
  }
  updateStyles() {
    this.setState({
      style: window.pageYOffset > 64 ? "compact" : "normal",
    });
  }

  onClick() {
    window.location.href =
      "mailto:surocode.technologies@gmail.com?Subject=Business%20Inquiries";
  }

  render() {
    return (
      <nav className="navbar fixed-top navbar-expand-lg navbar-light">
        <a className="navbar-brand" href="#">
          <NavLink className="nav-link" to="/overview">
            <img src={require("./logo.png")} width="70" height="70" alt="" />
          </NavLink>
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="nav navbar-nav ml-auto">
            <li
              className="nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <NavLink
                className="nav-link"
                to="/mobileapp"
                activeStyle={{ color: "#78ADFD" }}
              >
                Mobile Apps
              </NavLink>
            </li>
            <li
              className="nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <NavLink
                className="nav-link"
                to="/webapp"
                activeStyle={{ color: "#78ADFD" }}
              >
                Web Apps
              </NavLink>
            </li>
            <li
              className="nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <NavLink
                className="nav-link"
                to="/uiux"
                activeStyle={{ color: "#78ADFD" }}
              >
                UI/UX
              </NavLink>
            </li>
            <li
              className="nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <NavLink
                className="nav-link"
                to="/cost-calculator"
                activeStyle={{ color: "#78ADFD" }}
              >
                Cost Calculator
              </NavLink>
            </li>
            <li
              className="nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <NavLink
                className="nav-link"
                to="/contact"
                activeStyle={{ color: "#78ADFD" }}
              >
                Contact
              </NavLink>
            </li>
            <li
              className="nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <NavLink
                className="nav-link"
                to="/about"
                activeStyle={{ color: "#78ADFD" }}
              >
                About
              </NavLink>
            </li>
          </ul>
          <button
            type="button"
            class="btn btn-outline-primary"
            onClick={this.onClick}
          >
            GET A QUOTE
          </button>
        </div>
      </nav>
    );
  }
}

const Footer = () => (
  <footer className="footer fixed-bottom">
    <div className="container">
      <FooterContent />
    </div>
  </footer>
);

const App = () => (
  <Router>
    <div className="wrapper">
      <Navigator />
      <main
        style={{
          backgroundImage: "url(" + require("./bg_img.png") + ")",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <Route exact path="/" render={() => <Redirect to="/overview" />} />

        <AnimatedSwitch
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
          mapStyles={mapStyles}
          className="route-wrapper"
        >
          <Route exact path="/overview" component={Overview} />
          <Route exact path="/mobileapp" component={Mobileapp} />
          <Route exact path="/webapp" component={Webapp} />
          <Route exact path="/uiux" component={Uiux} />
          <Route exact path="/cost-calculator" component={CostCalculator} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/about" component={About} />
        </AnimatedSwitch>
      </main>
      <Footer />
    </div>
  </Router>
);

ReactDOM.render(<App />, document.getElementById("root"));
