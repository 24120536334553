import React, { Component } from "react";
import "./costCalculator.css";
import BaseService from "../common/BaseService";
import Constant from "../common/Constant";

const NAME_INPUT = "nameInput";
const EMAIL_INPUT = "emailInput";
const ADDITIONAL_INPUT = "additionalInput";

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const NUMBER_REGEX = /^\d+$/;
const TEXT_REGEX = /^[a-zA-Z/ /]+$/;

class CostCalculator extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.selectedQuestion = { totalPrice: 0, estimatePrice: "", selected: {} };
    this.state = {
      isShowLanding: true,
      isShowSummary: false,
      isShowResult: false,
      isShowConsultationView: false,
      questionData: null,
      currentQuestion: null,
      isSendEmail: true,
      userName: "",
      email: "",
      additionalData: "",
      nameErrorMessage: "",
      emailErrorMessage: "",
      additionalErrorMessage: "",
      isValidName: true,
      isValidEmail: true,
      isValidAdditional: true,
    };
  }

  render() {
    return (
      <div className="main-page index">
        <section>
          <div className="container">
            <div class="row">{this.displayCostScreenContent()}</div>
          </div>
        </section>
      </div>
    );
  }

  /**
   * Get view for display cost calculator content
   */
  displayCostScreenContent() {
    if (this.state.isShowLanding) {
      return this.getLandingView();
    } else if (this.state.isShowResult) {
      return this.getCostResultView();
    } else if (this.state.isShowConsultationView) {
      return this.getCostConsultationView();
    } else if (this.state.isShowSummary) {
      return this.getCostSummaryView();
    } else {
      return this.getQuestionView();
    }
  }

  /**
   * Action handler when user click "NEXT QUESTION >" button
   */
  onNextQuestion() {
    if (this.currentIndex + 1 < this.state.questionData.length) {
      this.currentIndex += 1;
      this.setState({
        currentQuestion: this.state.questionData[this.currentIndex],
      });
    } else if (this.currentIndex === this.state.questionData.length - 1) {
      if (this.selectedQuestion.totalPrice === 0) {
        alert("You haven't select any requirement");
      } else {
        this.setState({ isShowSummary: true });
      }
    }
  }

  /**
   * Action handler when user click "< GO BACK" text
   */
  onBackQuestion() {
    if (this.currentIndex > 0) {
      this.currentIndex -= 1;
      this.setState({
        currentQuestion: this.state.questionData[this.currentIndex],
      });
    }
  }

  /**
   * Action handler when user select or unselect question
   * @param {JsonObject} question
   * @param {Integer} position
   */
  onSelectQuestion(question, position) {
    const selectedQuestion = this.state.currentQuestion;

    if (selectedQuestion.selection === "single") {
      for (let i = 0; i < selectedQuestion.questions.length; i++) {
        if (selectedQuestion.questions[i].isSelected) {
          this.selectedQuestion.totalPrice -=
            selectedQuestion.questions[i].price;
        }
        selectedQuestion.questions[i].isSelected = false;
      }
    }

    selectedQuestion.questions[position].isSelected =
      !selectedQuestion.questions[position].isSelected;

    const populateQuestions = [];
    for (let i = 0; i < selectedQuestion.questions.length; i++) {
      if (selectedQuestion.questions[i].isSelected) {
        populateQuestions.push(selectedQuestion.questions[i]);
      }
    }

    if (selectedQuestion.questions[position].isSelected) {
      this.selectedQuestion.totalPrice += question.price;
    } else {
      this.selectedQuestion.totalPrice -= question.price;
    }

    const totalPrice = this.selectedQuestion.totalPrice;
    const lowerPrice =
      totalPrice - 1000 > 0 ? totalPrice - 1000 : totalPrice - 500;
    const highPrice =
      totalPrice - 1000 > 0 ? totalPrice + 1000 : totalPrice + 500;
    this.selectedQuestion.estimatePrice =
      totalPrice > 0 ? `$${lowerPrice} - $${highPrice}` : "";

    const titleSplit = selectedQuestion.title.split(" - ");
    const key = titleSplit[1].replace(/ /g, "_");
    this.selectedQuestion.selected[key] = populateQuestions;
    this.setState({ currentQuestion: selectedQuestion });
  }

  /**
   * Get Cost Calculator landing view
   */
  getLandingView() {
    return (
      <div className="span4">
        <img
          className="img-right"
          src={require("../mobileapp/PIC 5-01.png")}
          alt=""
          width="50%"
        />
        <div className="content-heading mobile-apps-cost-cal">
          <h3>Mobile Apps Cost Calculator</h3>
        </div>
        <div className="how-much-does-it-cos">
          <p>How much does it cost to build a mobile app?</p>
          <p>
            Use our handy Mobile App Cost Calculator tool to estimate how much
            would it cost to develop an iOS, Android or Hybrid Mobile
            Application.
          </p>
        </div>
        <p className="all-estimates-are-ap">
          All estimates are approximate but should definitely give a rough idea
          of what it will cost to build your mobile app.
        </p>
        <button
          type="button"
          className="btn btn-outline-primary button-rectangle"
          onClick={() => {
            BaseService.doGet(`${Constant.BASE_URL}${Constant.PRICING_URL}`)
              .then((response) => {
                this.setState({
                  isShowLanding: false,
                  questionData: response.pricingRules,
                  currentQuestion: response.pricingRules[this.currentIndex],
                });
              })
              .catch((error) => {
                console.log("error", error);
                alert(Constant.ERROR_REQUEST_MESSAGE);
              });
          }}
        >
          GET STARTED
        </button>
      </div>
    );
  }

  /**
   * Get Cost Calculator questions view
   */
  getQuestionView() {
    const question = this.state.currentQuestion;
    return (
      <div className="question-form-container">
        <div className="content-heading">
          <h3 className="question-title">{question.title}</h3>
          <p className="question-subtitle">{question.subTitle}</p>
        </div>
        <div className="inline-block">
          {question.questions.map((data, index) => {
            const imageName = data.isSelected
              ? `${data.image}-colored.svg`
              : `${data.image}-grey.svg`;
            const selectedBorder = data.isSelected ? "question-selected" : "";
            return (
              <div
                key={index}
                className={`question-container cursor-pointer ${selectedBorder}`}
                onClick={() => {
                  this.onSelectQuestion(data, index);
                }}
              >
                <div className="questions-image-container">
                  <img
                    className="questions-image"
                    src={require(`./images/${imageName}`)}
                  ></img>
                </div>
                <div className="questions-title-container">
                  <span className="questions-title">{data.name}</span>
                </div>
              </div>
            );
          })}
          <div>
            {this.currentIndex !== 0 ? (
              <div
                className="question-go-back question-go-back-width"
                onClick={() => {
                  this.onBackQuestion();
                }}
              >
                {"< GO BACK"}
              </div>
            ) : (
              <div className="question-go-back-width"></div>
            )}
            <button
              type="button"
              className="btn btn-outline-primary button-rectangle question-next-btn"
              onClick={() => {
                this.onNextQuestion();
              }}
            >
              NEXT QUESTIONS >
            </button>
          </div>
        </div>
        <div className="inline-block cost-estimate-container">
          <p className="cost-estimation-text">Cost Estimation</p>
          <p className="question-container cost-estimation-value">
            {this.selectedQuestion.estimatePrice !== ""
              ? this.selectedQuestion.estimatePrice
              : "$0"}
          </p>
        </div>
      </div>
    );
  }

  /**
   * Get Cost Summary View
   */
  getCostSummaryView() {
    return (
      <div className="summary-container">
        <div className="content-heading mobile-apps-cost-cal">
          Mobile Apps Cost Calculator
        </div>
        <p className="summary-cost-price">
          {this.selectedQuestion.estimatePrice !== ""
            ? this.selectedQuestion.estimatePrice
            : "$0"}
        </p>
        <p className="summary-cost-approxima">
          This is an approximate estimated range. The exact cost could vary
          between this range depending on the complexity of each feature in the
          app.
        </p>
        <p className="summary-cost-approxima">
          Please contact us to get a free 30 minute consultation to talk about
          your app, technologies, effort, timeline and cost which will help you
          understand neatly the process and effort involved in developing your
          dream app.
        </p>
        <div className="summary-btn-container">
          <button
            type="button"
            className="btn btn-outline-primary button-rectangle summary-btn"
            onClick={() => {
              this.setState({ isShowConsultationView: true });
            }}
          >
            FREE CONSULTATION WITH US
          </button>
          <button
            type="button"
            className="btn btn-outline-primary summary-btn"
            onClick={() => {
              this.setState({
                isShowConsultationView: true,
                isSendEmail: true,
              });
            }}
          >
            SENT ESTIMATION TO YOUR EMAIL
          </button>
        </div>
      </div>
    );
  }

  /**
   * Get Cost Result View
   */
  getCostResultView() {
    return (
      <div className="summary-container cost-result-container">
        <img
          className="cost-result-check-list"
          src={require("./images/check-list.svg")}
        />
        <div className="content-heading mobile-apps-cost-cal">
          Thank you for your enquiry!
        </div>
        <p className="summary-cost-approxima cost-result-text">
          We will get back to you soon. Please check your email to fix your
          consultation schedule.
        </p>
        <button
          type="button"
          className="btn btn-outline-primary button-rectangle summary-btn"
          onClick={() => {
            this.props.history.push("/mobileapp");
          }}
        >
          BRING ME TO HOMEPAGE
        </button>
      </div>
    );
  }

  /**
   * Get Cost Consultation View
   */
  getCostConsultationView() {
    const headerTitle = this.state.isSendEmail
      ? "Send Your Email"
      : "Let’s Discuss!";
    const additionalText = this.state.isSendEmail
      ? "Application Name"
      : "Phone (Optional)";
    return (
      <div className="summary-container cost-result-container">
        <div className="content-heading mobile-apps-cost-cal text-align-left">
          {headerTitle}
        </div>
        <p className="summary-cost-approxima cost-result-subtitle">
          We will get back to you within 24 hours with details about your free
          consultation.
        </p>
        <input
          className="cost-result-input-text"
          type="text"
          value={this.state.userName}
          placeholder="Your Name"
          onChange={(event) => {
            this.setState({ userName: event.target.value });
          }}
          onBlur={(event) => this.onBlurAction(event.target.value, NAME_INPUT)}
        />
        {this.state.isValidName ? null : (
          <p className="error-input">{this.state.nameErrorMessage}</p>
        )}
        <input
          className="cost-result-input-text"
          type="text"
          value={this.state.email}
          placeholder="Email"
          onChange={(event) => {
            this.setState({ email: event.target.value });
          }}
          onBlur={(event) => this.onBlurAction(event.target.value, EMAIL_INPUT)}
        />
        {this.state.isValidEmail ? null : (
          <p className="error-input">{this.state.emailErrorMessage}</p>
        )}
        <input
          className="cost-result-input-text"
          type="text"
          value={this.state.additionalData}
          placeholder={additionalText}
          onChange={(event) => {
            this.setState({ additionalData: event.target.value });
          }}
          onBlur={(event) =>
            this.onBlurAction(event.target.value, ADDITIONAL_INPUT)
          }
        />
        {this.state.isValidAdditional ? null : (
          <p className="error-input">{this.state.additionalErrorMessage}</p>
        )}
        <button
          type="button"
          className="btn btn-outline-primary button-rectangle summary-btn cost-result-btn"
          onClick={() => {
            this.sendCostCalculator();
          }}
        >
          SENT MY REQUEST
        </button>
      </div>
    );
  }

  /**
   * Input Text onBlur Handler
   * @param {String} value input text value
   * @param {String} type input text type
   */
  onBlurAction(value, type) {
    const stateObj = {};
    var errorMessage = "";
    var errorKey = "";
    var validInputKey = "";
    var isValidInput = true;
    var isValidInputKey = "";

    if (type === NAME_INPUT) {
      validInputKey = "userName";
      errorKey = "nameErrorMessage";
      isValidInputKey = "isValidName";
      errorMessage = Constant.INVALID_NAME;
      isValidInput = this.isValidText(value);
    } else if (type === EMAIL_INPUT) {
      validInputKey = "email";
      errorKey = "emailErrorMessage";
      isValidInputKey = "isValidEmail";
      errorMessage = Constant.INVALID_EMAIL;
      isValidInput = this.isValidEmail(value);
    } else if (type === ADDITIONAL_INPUT) {
      validInputKey = "additionalData";
      errorKey = "additionalErrorMessage";
      isValidInputKey = "isValidAdditional";
      errorMessage = this.state.isShowLanding
        ? Constant.INVALID_APPLICATION_NAME
        : Constant.INVALID_PHONE;

      if (this.state.isSendEmail) isValidInput = this.isValidText(value);
      else if (value !== "" && !this.state.isSendEmail)
        isValidInput = this.isValidNumber(value);
    }

    if (value === "") {
      stateObj[errorKey] = Constant.FIELD_REQUIRED;
    } else if (!isValidInput) {
      stateObj[errorKey] = errorMessage;
    } else {
      stateObj[validInputKey] = value;
    }

    stateObj[isValidInputKey] = isValidInput;

    this.setState(stateObj);
  }

  isValidInput() {
    const isValidName = this.state.isValidName && this.state.userName !== "";
    const isValidEmail = this.state.isValidEmail && this.state.email !== "";
    var isValidAddition = true;

    if (this.state.additionalData !== "")
      isValidAddition = this.state.isValidAdditional;

    return isValidName && isValidEmail && isValidAddition;
  }

  /**
   * Do Request cost calculator
   */
  sendCostCalculator() {
    if (this.isValidInput()) {
      const postBody = {
        totalPrice: this.selectedQuestion.totalPrice,
        estimatePrice: this.selectedQuestion.estimatePrice,
        user: {
          name: this.state.userName,
          email: this.state.email,
        },
        data: [],
      };

      const additionalKey = this.state.isSendEmail
        ? "applicationName"
        : "phoneNumber";
      postBody.user[additionalKey] = this.state.additionalData;

      this.setState({ isShowResult: true });
      Object.keys(this.selectedQuestion.selected).forEach((key) => {
        const dataObj = {
          type: key.replace(/_/g, " "),
          list: this.selectedQuestion.selected[key],
        };
        postBody.data.push(dataObj);
      });
    } else {
      alert("Input Field is Required.");
    }
  }

  /**
     Will validate email text field
     */
  isValidEmail(email) {
    return (
      email.match(this.ALLOWED_EMAIL_CHARACTER) !== null &&
      EMAIL_REGEX.test(email)
    );
  }

  /**
   * Will validate number text field
   */
  isValidNumber(number) {
    return NUMBER_REGEX.test(number);
  }

  /**
    Will validate alphabet text field
    */
  isValidText(text) {
    return TEXT_REGEX.test(text);
  }
}

export default CostCalculator;
