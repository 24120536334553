import React, { Component } from "react";
import "./about.css";

class About extends Component {
  onClick() {
    window.location.href =
      "mailto:surocode.technologies@gmail.com?Subject=Business%20Inquiries";
  }

  render() {
    return (
      <div className="main-page index">
        <section>
          <div className="container">
            <div class="row">
              <div class="span4">
                <img
                  class="img-right"
                  src={require("./PIC 2-01.png")}
                  alt=""
                  width="50%"
                />

                <div class="content-heading">
                  <h3>Hello, We Are Surocode!</h3>
                </div>
                <br />
                <p>
                  Surocode are specialists in software development and digital
                  content management with proven experience across a range of
                  digital content such as Android and iOS based applications,
                  web base, UI/UX design and web services for many industries
                  that need to enlarge their digital presence.
                </p>
                <br />
                <br />
                <br />

                <button
                  type="button"
                  class="btn btn-outline-primary"
                  onClick={this.onClick}
                >
                  GET A QUOTE
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary learn-more-button"
                >
                  LEARN MORE
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default About;
