import React, { Component } from "react";
import "./overview.css";

import Typist from "react-typist";

class Index extends Component {
  onClick() {
    window.location.href =
      "mailto:surocode.technologies@gmail.com?Subject=Business%20Inquiries";
  }

  render() {
    return (
      <div className="main-page index">
        <section>
          <div className="container">
            <div class="row mb-4">
              <div class="span4">
                <img
                  class="img-right"
                  src={require("./PIC 1-01.png")}
                  alt=""
                  width="50%"
                />

                <div class="content-heading">
                  <h3>The Future is Today</h3>
                </div>
                <br />
                <p>
                  <Typist cursor={{ hideWhenDone: true }}>
                    <Typist.Delay ms={1000} />
                    Let us handle the hasle, <br />
                    So you can focus on what you love.
                  </Typist>
                </p>
                <br />
                <br />
                <br />
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  onClick={this.onClick}
                >
                  GET A QUOTE
                </button>
              </div>
            </div>

            <div class="text-center text-primary">
              <h2>Services to grow your business</h2>
            </div>
            <div class="text-center">
              We enhance user experiences and grow brands
            </div>
            <div class="text-center">
              by developing conversion-focused mobile applications and web
              development. Discover how.
            </div>

            <div class="row mb-5 ml-5 mr-5 mt-5 bg-white pt-5 pb-5 vdivide">
              <div class="col-lg-4 text-center">
                <img
                  class="img-right"
                  src={require("./PIC 6-01.png")}
                  alt=""
                  width="50%"
                />
                <div class="text-center text-primary">
                  <h5>Mobile App Development</h5>
                </div>
                <div class="text-center mt-3 text-muted">
                  Mobile applications are now an essential business tool across
                  all industries in the government and private sectors in order
                  to provide excellent customer service and remain competitive
                  and grow. Surocode has developed and implemented both Android
                  and iOS applications working closely with our clients
                  throughout the process.
                </div>
                <div class="text-center text-primary mt-5">
                  <a href="#">Learn More ></a>
                </div>
              </div>
              <div class="col-lg-4 text-center">
                <img
                  class="img-right"
                  src={require("./PIC 7-01.png")}
                  alt=""
                  width="50%"
                />
                <div class="text-center text-primary">
                  <h5>Web App App Development</h5>
                </div>
                <div class="text-center mt-3 text-muted">
                  Whether you are a start up company or an established business,
                  we are ready to assist you. We can develop applications that
                  can optimize your business processes like payroll, absence
                  check and data management. Surocode also has the expertise to
                  a build systems for any business function.
                </div>
                <div class="text-center text-primary mt-5">
                  <a href="#">Learn More ></a>
                </div>
              </div>
              <div class="col-lg-4 text-center">
                <img
                  class="img-right"
                  src={require("./PIC 8-01.png")}
                  alt=""
                  width="50%"
                />
                <div class="text-center text-primary">
                  <h5>UI/UX Development</h5>
                </div>
                <div class="text-center mt-3 text-muted">
                  Having a good responsive and interactive website is crucial.
                  We have built and implemented many web based applications such
                  as e-commerce, e-learning, AdTech, financial, entertainment
                  and many more.
                </div>
                <div class="text-center text-primary mt-5">
                  <a href="#">Learn More ></a>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="span4 mt-5">
                <img
                  class="img-right"
                  src={require("./PIC 3-01.png")}
                  alt=""
                  width="50%"
                />

                <div class="content-heading">
                  <h3>Why Surocode?</h3>
                </div>
                <br />
                <p>
                  Our team is oriented towards innovation, creativity and
                  sustainability. We create high value content and use the
                  latest technology to deliver the best outcomes for your
                  customer.
                </p>
                <br />
                <br />
                <br />
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  onClick={this.onClick}
                >
                  GET A QUOTE
                </button>
              </div>
            </div>

            <img
              class="mt-5 d-block mx-auto"
              src={require("../logo.png")}
              width="100"
              height="100"
              alt=""
            />
            <div class="mt-5 text-center text-muted">
              <h5>
                "Digital content is the first step to <br /> build your online{" "}
                <br />
                presence"
              </h5>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Index;
