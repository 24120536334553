import React from "react";
import "./contact.css";

const Contact = () => (
  <div className="main-page index">
    <section>
      <div className="container">
        <div class="row">
          <div class="span4">
            <img
              class="img-right"
              src={require("./PIC 10-01.png")}
              alt=""
              width="50%"
            />

            <div class="content-heading">
              <h3>Contact Us!</h3>
            </div>
            <br />
            <p>We would love to hear from you</p>
            {/* <form>      
  <input name="name" type="text" class="feedback-input" placeholder="Name" />   
  <input name="email" type="text" class="feedback-input" placeholder="Email" />
  <textarea name="text" class="feedback-input" placeholder="Comment"></textarea>
  <input type="submit" value="SUBMIT"/>
</form> */}

            <br />

            <h4 class="text-primary">
              <a
                href="mailto:surocode.technologies@gmail.com?Subject=Business%20Inquiries"
                target="_top"
              >
                surocode.technologies@gmail.com
              </a>
            </h4>

            <br />
            <br />

            <p class="text-muted">10 Stephenson Street</p>
            <p class="text-muted">Roserlands, New South Wales 2196.</p>
            <p class="text-muted">Australia.</p>

            <br />
            <p class="text-muted">Phone: +61 431 264 251</p>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default Contact;
