import React, { Component } from "react";
import "./uiux.css";

class Uiux extends Component {
  onClick() {
    window.location.href =
      "mailto:surocode.technologies@gmail.com?Subject=Business%20Inquiries";
  }

  render() {
    return (
      <div className="main-page index">
        <section>
          <div className="container">
            <div class="row">
              <div class="span4">
                <img
                  class="img-right"
                  src={require("./PIC 9-01.png")}
                  alt=""
                  width="50%"
                />

                <div class="content-heading">
                  <h3>UI/UX</h3>
                </div>
                <br />
                <p>
                  Having a good responsive and interactive website and app is
                  crucial. We have design and implemented many web based and
                  mobile applications such as e-commerce, e-learning, AdTech,
                  financial, entertainment and many more.
                </p>
                <br />
                <br />
                <br />
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  onClick={this.onClick}
                >
                  GET A QUOTE
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary learn-more-button"
                >
                  LEARN MORE
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Uiux;
