import React from "react";
import "./FooterContent.css";

const Footer = () => (
  <footer class="page-footer font-small blue">
    <div class="row d-flex align-items-center">
      <div class="col-md-7 col-lg-8">
        <p class="text-center text-md-left text-light mt-3">
          © 2024 Surocode all rights reserved
        </p>
      </div>

      <div class="col-md-5 col-lg-4 ml-lg-0">
        <div class="text-center text-md-right mt-4">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item">
              <a href="https://web.facebook.com/surocodeaustralia">
                <p class="text-light">facebook</p>
              </a>
            </li>
            <li class="list-inline-item ml-3">
              <a href="https://twitter.com/surocode">
                <p class="text-light">twitter</p>
              </a>
            </li>
            <li class="list-inline-item ml-3">
              <a href="https://www.linkedin.com/in/surocode-australia-4b99b9165/">
                <p class="text-light">linkedin</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
