import React, { Component } from "react";

import "./webapp.css";

class Webapp extends Component {
  onClick() {
    window.location.href =
      "mailto:surocode.technologies@gmail.com?Subject=Business%20Inquiries";
  }

  render() {
    return (
      <div className="main-page index">
        <section>
          <div className="container">
            <div class="row">
              <div class="span4">
                <img
                  class="img-right"
                  src={require("./PIC 4-01.png")}
                  alt=""
                  width="50%"
                />

                <div class="content-heading">
                  <h3>Website Development</h3>
                </div>
                <br />
                <p>
                  Whether you are a start up company or an established business,
                  we are ready to assist you. We can develop application that
                  can optimize your business process like payroll, absence
                  check, data management, micro site and company profile
                  website. Surocode also has the expertise to a build system for
                  any business function.
                </p>
                <br />
                <br />
                <br />
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  onClick={this.onClick}
                >
                  GET A QUOTE
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary learn-more-button"
                >
                  LEARN MORE
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Webapp;
