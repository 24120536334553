const getReqConfig = (methods, headers = null, body = null, timeout = 30000) => {
    const reqConfig = {
        method: methods,
        timeout: timeout
    };

    if (headers !== null) {
        reqConfig['headers'] = headers;
    }

    if (body !== null) {
        reqConfig['body'] = body;
    }

    return reqConfig;
};

/**
  This class contains basic functionality for service related functionality.
 */
const BaseServices = {

    /**
     * Do Get Request
     * @param {String} url http(s) url
     */
    doGet(url) {
        return new Promise((resolve, reject) => {
            fetch(url, getReqConfig('GET'))
                .then(response => {
                    this.responseHanlder(response, resolve, reject);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    /**
     * 
     * @param {HttpResponse} response request response
     * @param {Promise} resolve success promise
     * @param {Promise} reject error promise
     */
    responseHanlder(response, resolve, reject) {
        if (response.status === 200 || response === 201) {
            try {
                response.json()
                    .then(jsonObj => {
                        resolve(jsonObj);
                    })
                    .catch(error => {
                        reject(error);
                    })
            } catch (error) {
                reject(error);
            }
        }
    }
}

export default BaseServices;