import React, { Component } from "react";
import "./mobileapp.css";

class Mobileapp extends Component {
  onClick() {
    window.location.href =
      "mailto:surocode.technologies@gmail.com?Subject=Business%20Inquiries";
  }

  render() {
    return (
      <div className="main-page index">
        <section>
          <div className="container">
            <div class="row">
              <div class="span4">
                <img
                  class="img-right"
                  src={require("./PIC 5-01.png")}
                  alt=""
                  width="50%"
                />

                <div class="content-heading">
                  <h3>Mobile App Development</h3>
                </div>
                <br />
                <p>
                  Mobile applications are now an essential business tool across
                  all industries in government and private sectors in order to
                  provide excellent customer service and remain competitive and
                  grow. Surocode has developed and implement both Android and
                  IOS applications working closely with our clients throughout
                  the process.
                </p>
                <br />
                <br />
                <br />
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  onClick={this.onClick}
                >
                  GET A QUOTE
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary learn-more-button"
                >
                  LEARN MORE
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Mobileapp;
